export default {
  alert: {
    admin_created: 'Nouvel administrateur créé',
    all_picos_unlocked: 'Tous les picos ont été déverrouillés avec succès',
    base_rebooted: 'La base a redémarré avec succès',
    base_updated: 'Base mise à jour avec succès',
    information_updated: 'Informations mises à jour avec succès',
    invalid_auth: 'Les données ne correspondent pas ou l`utilisateur n`existe pas.',
    invalid_tfa_code: 'Code invalide ou expiré',
    passwords_dont_match: 'Les mots de passe ne correspondent pas',
    something_goes_wrong: 'Quelque chose ne va pas..',
    slot_released: 'Les fentes sur la base ont été libérées',
    ticket_closed: 'Ticket clôturé avec succès',
    ticket_created: 'Ticket créé avec succès',
    update_request_sent: 'Demande de mise à jour envoyée',
    user_created: 'Utilisateur créé avec succès',
    user_deleted: 'Utilisateur supprimé avec succès',
    user_restored: 'Utilisateur restauré avec succès',
    user_updated: 'Utilisateur mis à jour avec succès',
    users_import_started: 'L`importation des utilisateurs a commencé',
  },
  btn: {
    activate_user: 'Activer l`utilisateur',
    add: 'Ajouter',
    back_home: 'Retour à la accueil',
    cancel: 'Annuler',
    close_ticket: 'Fermer le ticket',
    create_ticket: 'Créer un nouveau ticket',
    create_user: 'Créer un utilisateur',
    details: 'Détails',
    delete: 'Effacer',
    delete_user: 'Supprimer l`utilisateur',
    download: 'Télécharger',
    edit_user: 'Modifier l`utilisateur',
    hide_filters: 'Masquer les filtres',
    logout: 'Se déconnecter',
    mark_as_read: 'Marquer comme lu',
    no: 'Non',
    ok: 'D`accord',
    reboot: 'Redémarrer',
    save_base_info: 'Enregistrer les informations de base',
    save_bases_pico_info: 'Enregistrer les informations Bases/Pico',
    save_general_info: 'Enregistrer les informations générales',
    save_import_users: 'Enregistrer l`importation d`utilisateurs',
    save_notifications_settings: 'Enregistrer les paramètres de notification',
    see_all_notifications: 'Voir toutes les notifications',
    see_more: 'Voir plus',
    send: 'Envoyer',
    set_password: 'Définir le mot de passe',
    set_user_as_admin: 'Définir un utilisateur comme admin',
    show_filters: 'Afficher les filtres',
    show_image: 'Afficher l`image',
    show_ticket: 'Afficher le ticket',
    forgot_password: 'Mot de passe oublié',
    reset_password: 'Réinitialiser le mot de passe',
    unlock: 'Déverrouiller',
    unlock_all_picos: 'Déverrouiller tous les picos',
    update: 'Mettre à jour',
    update_user: 'Mettre à jour l`utilisateur',
    upload: 'Télécharger',
    upload_image: 'Télécharger une image',
    yes: 'Oui',
  },
  error: {
    email_or_nfc_id_required: 'Le champ ID badge NFC ou e-mail est obligatoire',
    invalid: 'Le champ est invalide',
    invalid_email: 'Le courriel est invalide',
    password_min_length_8: 'La longueur minimale du mot de passe est de 8 symboles',
    required: 'Champ requis',
    user_with_email_not_found: 'Utilisateur avec cet e-mail introuvable',
    user_not_found: 'Utilisateur non trouvé'
  },
  label: {
    accelero_max: 'AccéléroMax',
    active_bases_out_of_total: 'Bases actives sur {count}',
    active_users: 'Utilisateurs actifs',
    admin: 'Admin',
    admins: 'Administrateurs',
    advanced_details: 'Détails avancés',
    alias: 'Alias',
    all: 'Tout',
    autoimport: 'Importation automatique',
    available: 'Disponible',
    available_pico: 'Pico disponible',
    average_peak_hour: 'Heure de pointe moyenne',
    base: 'Base',
    base_alias: 'Base alias',
    base_details: 'Détails du base',
    base_from: 'Base de',
    base_id: 'Base ID',
    base_to: 'Base à',
    bases_pico: 'Bases/Pico',
    bases_with_active_status: 'Bases avec statut actif',
    categories: 'Catégories',
    category: 'Catégorie',
    code: 'Code',
    chosen_notifications: 'Notifications choisies',
    coming_soon: 'Bientôt disponible',
    confirm_password: 'Confirmez le mot de passe',
    contact: 'Contact',
    contact_for_request: 'Pour toute demande, merci de contacter',
    coordinates: 'Coordonnés',
    create_password: 'Créer un nouveau mot de passe',
    create_user: 'Créer un utilisateur',
    current_pico_users: 'Utilisateurs actuels de pico',
    current_users: 'Utilisateurs actuels',
    date: 'Date',
    date_of_last_update: 'Date de la dernière mise à jour',
    date_of_unlock: 'Date de déverrouillage',
    days: 'Journées',
    description: 'La description',
    details: 'Détails',
    download_template: 'Télécharger le modèle standard Osol',
    email: 'Email',
    email_notifications: 'Notifications par email',
    enable_gps: 'Activer la collecte de données GPS',
    filters: 'Filtres',
    first_name: 'Prénom',
    general: 'Général',
    hello: 'Bonjour',
    history: 'Historique',
    holding_time: 'Durée d`utilisation',
    hours: 'Heures',
    import_of_users: 'Importation d`utilisateurs',
    information: 'Informations',
    issue: 'Problème',
    issues: 'Problèmes',
    language: 'Langue',
    last_autoimport: 'Dernière importation automatique',
    last_name: 'Nom de famille',
    last_update: 'Dernière mise à jour',
    len_log: 'Journal de len',
    limit_authorized_area: 'Limiter la zone autorisée',
    limit_holding_time: 'Définir la durée d`utilisation',
    link_logo: 'Logo du lien',
    link_with_organization: 'Lien avec l`organisation',
    linked_organization: 'Organisation liée',
    list_of_organizations: 'Liste des organisations',
    location: 'Emplacement',
    location_of_installation: 'Lieu d`implantation',
    lock_mode: 'Verrouillage',
    log_in: 'Connexion',
    long_alias: 'Alias long',
    maintenance_mode: 'Mode de Maintenance',
    managers_full_name: 'Nom et prénom du gérant',
    map: 'Carte',
    maximum_radius: 'Rayon maximum autorisé',
    minutes: 'Minutes',
    mobile_app_notifications: 'Notifications d`applications mobiles',
    my_personal_template: 'Paramètre de mon modèle personnel pour l`importation automatique',
    my_problem_is_about: 'Mon problème concerne',
    name: 'Nom',
    new_password: 'Nouveau mot de passe',
    new_password_again: 'Retapez le nouveau mot de passe',
    nfc_badge_id: 'ID de badge NFC',
    nfc_badge_reader: 'Lecteur de badge NFC',
    nfc_id: 'NFC ID',
    no_data: 'Pas de données',
    no_new_notifications: 'Pas de nouvelles notifications',
    not_returned_pico: 'Pico non retourné',
    not_selected: 'Non selectionné',
    notifications: 'Notifications',
    number_of_pico_general: 'Nombre de Pico Général',
    number_of_pico_unlocked: 'Nombre de Pico déverrouillés',
    number_of_pico_used_by_one_user: 'Nombre de Pico pouvant être utilisés par un utilisateur',
    number_of_users_added: 'Nombre d`utilisateurs ajoutés',
    number_of_users_deleted: 'Nombre d`utilisateurs supprimés',
    old_password: 'Ancien mot de passe',
    organization: 'Organisation',
    organization_name: 'Nom de l`organisation',
    password: 'Mot de passe',
    password_recovery: 'Récupération de mot de passe',
    personal_info: 'Informations personnelles',
    phone_number: 'Numéro de téléphone',
    pico_alias: 'Pico alias',
    pico_id: 'Pico ID',
    pico_taken: 'Pico pris',
    power_in: 'Puissance dans',
    position: 'Position',
    positions: 'Postes',
    unread_only: 'Non lu uniquement',
    urgency: 'Urgence',
    request_was_sent: 'La demande a été envoyée',
    response: 'Réponse',
    search: 'Chercher',
    settings: 'Réglages',
    short_alias: 'Alias court',
    single_import: 'Importation unique',
    slot: 'Insérer',
    soc: 'SOC',
    soh: 'SOH',
    status: 'Statut',
    status_active: 'Statut actif',
    status_inactive: 'Statut inactif',
    super_users: 'Super utilisateurs',
    ticket: 'Ticket',
    tickets: 'Des tickets',
    time: 'Temps',
    total_consumption: 'Consommation totale',
    total_time: 'Temps total',
    type_of_notification: 'Type de notification',
    type_of_problem: 'Type de problème',
    unread_tickets: 'Billets non lus',
    upload_list_of_users: 'Télécharger la liste des utilisateurs',
    urgent_notifications: 'Notifications urgentes',
    usb_c_100_w: 'USB-c 100W',
    user_id: 'Identifiant d`utilisateur',
    user_notifications: 'Notifications utilisateur',
    users: 'Utilisateurs',
    web_notifications: 'Notifications web',
    workplace_mobility: 'Mobilité sur le lieu de travail',
    you: 'Vous',
  },
  modal: {
    activate_user: 'Êtes-vous sûr de vouloir activer l`utilisateur?',
    close_ticket: 'Êtes-vous sûr de vouloir fermer le ticket?',
    delete_user: 'Êtes-vous sûr de vouloir supprimer l`utilisateur?',
    release_slot: 'Pico sera déverrouillé de la station de base et obtiendra le statut supprimé',
  },
  navigation: {
    404: 'Page non trouvée',
    basedetails: 'Bases/Pico',
    bases: 'Bases/Pico',
    change_password: 'Changer le mot de passe',
    contact: 'Contacter Osol',
    contact_list: 'Mes tickets',
    contactlist: 'Mes tickets',
    create_ticket: 'Créer un ticket',
    dashboard: 'Tableau de bord',
    forgot_password: 'Mot de passe oublié',
    history: 'Historique',
    home: 'Accueil',
    login: 'Connexion',
    newcontact: 'Mes tickets',
    notifications: 'Notifications',
    reset_password: 'Réinitialiser le mot de passe',
    settings: 'Réglages',
    showcontact: 'Mes tickets',
    showticket: 'Des tickets',
    tickets: 'Des tickets',
    users: 'Utilisateurs'
  },
  placeholder: {
    enter_code: 'Entrez le code',
    enter_your_email: 'Entrer votre Email',
    enter_your_password: 'Entrez votre mot de passe',
    from: 'De',
    time_of_use_from: 'Temps d`utilisation à partir de',
    time_of_use_to: 'Temps d`utilisation pour',
    to: 'À',
    your_message: 'Votre message'
  },
  status: {
    active: 'Actif',
    deactivated: 'Désactivé',
    deleted: 'Supprimé',
    inactive: 'Inactif',
    locked: 'Fermé à clé',
    low_activity: 'Faible activité',
    off: 'Désactivé | Entretien',
    offline: 'Hors ligne',
    unlocked: 'Débloqué',
  },
  text: {
    check_email_for_recovery: 'Veuillez vérifier votre e-mail pour le lien de récupération du mot de passe',
    choose_params_for_notification: 'Choisir les paramètres pour les notifications',
    click_to_delete_image: 'Cliquez pour supprimer l\'image',
    contact_info: 'Le contact principal pour tout le support technique est',
    page_not_found: 'Désolé, nous n\'avons pas trouvé cette page',
  }
}