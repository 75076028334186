<template>
  <div>
    <a-row :gutter="32">
      <a-col :span="8" class="gutter-row">
        <form-input
            class="mb-32px"
            :label="$t('label.organization_name')"
            :placeholder="$t('label.organization_name')"
            :value="form.name"
            required
            :error="error.name"
            @filled="error.name = null"
            @input="form.name = $event"
        />
        <form-input
            class="mb-32px"
            :label="$t('label.email')"
            :placeholder="$t('label.email')"
            :value="form.email"
            required
            :error="error.email"
            @input="form.email = $event"
        />
        <form-input
            class="mb-32px"
            :label="$t('label.phone_number')"
            :placeholder="$t('label.phone_number')"
            :value="form.phone"
            required
            :error="error.phone"
            @keydown="validateNumber($event, ['+'])"
            @input="form.phone = $event"
        />
        <form-select
            class="mb-32px"
            :label="$t('label.link_with_organization')"
            :placeholder="$t('label.link_with_organization')"
            :value="form.linked_organizations"
            :options="computedLinkedOrganizationsList"
            multiple
            @change="form.linked_organizations = $event"
        />
        <form-input
            class="mb-32px"
            :label="$t('label.link_logo')"
            :placeholder="$t('label.link_logo')"
            :value="form.logo"
            :error="error.logo"
            @input="form.logo = $event"
        />
      </a-col>
      <a-col class="gutter-row" :span="8">
        <form-select
            class="mb-32px"
            :label="$t('label.language')"
            :placeholder="$t('label.language')"
            :value="form.language_id"
            :options="languages"
            required
            :error="error.language_id"
            @change="form.language_id = $event"
        />
        <form-select
            class="mb-32px"
            :label="$t('label.location')"
            :placeholder="$t('label.location')"
            :value="form.location.label"
            :options="relatedLocations"
            required
            :error="error.location"
            show-search
            :show-arrow="false"
            @blur="$store.commit('settingsStore/CLEAR_RELATED_LOCATIONS')"
            @change="form.location.label = $event"
            @search="findLocations"
        />
        <a-row :gutter="16">
          <a-col class="gutter-row" :span="12">
            <form-input
                class="mb-32px"
                :label="$t('label.coordinates')"
                :placeholder="$t('label.coordinates')"
                :value="form.location.longitude"
                required
                :error="error['location.longitude']"
                @input="form.location.longitude = $event"
            />
          </a-col>
          <a-col class="gutter-row" :span="12">
            <form-input
                class="mb-32px text-transparent"
                label="."
                :placeholder="$t('label.coordinates')"
                :value="form.location.latitude"
                :error="error['location.latitude']"
                @input="form.location.latitude = $event"
            />
          </a-col>
        </a-row>
        <div class="map-block">
          <app-location-map
              :show="!!formLocation"
              :selected-location="selectedLocation"
              ref="locationMap"
              refer="locationMap"
              @handleMapClick="handleMapClick"
          />
        </div>
      </a-col>
    </a-row>
    <a-button class="app-button submit-base-btn primary" @click="submit">{{ $t('btn.save_general_info') }}</a-button>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import validationMixin from '@/mixins/validation';
import AppLocationMap from '@/components/elements/AppLocationMap';
import FormInput from '@/components/elements/FormInput';
import FormSelect from '@/components/elements/FormSelect';

export default {
  components: {
    AppLocationMap,
    FormInput,
    FormSelect
  },
  mixins: [validationMixin],
  props: {
    organization: {type: Object, default: () => {}}
  },
  data: () => ({
    form: {
      name: null,
      email: null,
      phone: null,
      linked_organizations: [],
      logo: null,
      language_id: null,
      location: {label: null, longitude: null, latitude: null}
    },
    error: {
      name: null,
      email: null,
      phone: null,
      language_id: null,
      location: null,
      'location.longitude': null,
      'location.latitude': null,
      logo: null,
    },
    selectedLocation: null
  }),
  computed: {
    ...mapGetters({
      linkedOrganizationsList: 'organizationsStore/LINKED_DROPDOWN_LIST',
      relatedLocations: 'settingsStore/RELATED_LOCATIONS'
    }),
    computedLinkedOrganizationsList() {
      return this.linkedOrganizationsList.length && this.organization
          ? this.linkedOrganizationsList.filter(el => el.value !== this.organization.id)
          : [];
    },
    languages() {
      return [
        {value: 1, label: 'English'},
        {value: 2, label: 'Français'}
      ];
    },
    formLocation() {
      return this.form.location?.label;
    }
  },
  watch: {
    organization(data) {
      if (data) {
        this.$store.dispatch('organizationsStore/GET_LINKED_DROPDOWN_LIST');

        this.form.name = data.name;
        this.form.email = data.email;
        this.form.phone = data.phone;
        this.form.logo = data.logo;
        this.form.language_id = data.language_id;
        if (data.location) {
          this.findLocations(data.location.label);

          this.form.location.label = data.location?.label;
          this.form.location.longitude = data.location?.longitude;
          this.form.location.latitude = data.location?.latitude;
        }

        if (data.linked_organizations.length) {
          this.form.linked_organizations = data.linked_organizations;
        }
      }
    },
    formLocation(value) {
      // TODO прибрати цей костиль, коли буде натхнення і час
      if (this.relatedLocations.length) {
        this.selectedLocation = this.relatedLocations.find(el => el.label === value);
      } else {
        setTimeout(() => {
          this.selectedLocation = this.relatedLocations.find(el => el.value === value);

          if (!this.selectedLocation) {
            this.selectedLocation = this.relatedLocations[0];
          }
        }, 1000);
      }
    },
    selectedLocation(data) {
      if (data) {
        this.form.location.longitude = data.x.toString();
        this.form.location.latitude = data.y.toString();
      }
    }
  },
  methods: {
    findLocations(value) {
      if (value.length > 2) {
        this.$store.dispatch('settingsStore/GET_RELATED_LOCATIONS', value);
      }
    },
    handleMapClick() {
      console.log('handleMapClick');
    },
    clearError() {
      Object.entries(this.error).map(el => this.error[el[0]] = null);
    },
    validateForm() {
      this.error.name = !this.form.name ? this.$t('error.required') : null;
      if (!this.form.email) {
        this.error.email = this.$t('error.required');
      } else if (!this.validateEmail(this.form.email)) {
        this.error.email = this.$t('error.invalid_email');
      } else {
        this.error.email = null;
      }
      this.error.phone = !this.form.phone ? this.$t('error.required') : null;
      this.error.location = !this.form.location ? this.$t('error.required') : null;

      return !Object.values(this.error).some(v => v);
    },
    submit() {
      this.clearError();
      if (!this.validateForm()) return false;
      this.$store.dispatch('organizationsStore/UPDATE', {id: this.organization.id, data: this.form})
          .then(() => {
            this.$message.success(this.$t('alert.information_updated'));
          })
      .catch(error => {
        if (error.response.status === 422) {
          for (const [key, errorMessage] of Object.entries(error.response.data.error.errors)) {
            this.error[key] = errorMessage[0];
          }
        } else {
          this.$message.error(error.response.data?.error?.message || this.$t('alert.something_goes_wrong'));
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.text-transparent::v-deep {
  .input-label {
    color: transparent;
  }
}
</style>