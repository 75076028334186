export default {
  alert: {
    admin_created: 'New admin created',
    all_picos_unlocked: 'All picos successfully unlocked',
    base_rebooted: 'Base successfully rebooted',
    base_updated: 'Base successfully updated',
    information_updated: 'Information successfully updated',
    invalid_auth: 'Credentials do not match or user doesn’t exist',
    invalid_tfa_code: 'Invalid or expired code',
    passwords_dont_match: 'Passwords do not match',
    something_goes_wrong: 'Something goes wrong..',
    slot_released: 'Slot of the base has been released',
    ticket_closed: 'Ticket successfully closed',
    ticket_created: 'Ticket successfully created',
    update_request_sent: 'Update request sent',
    user_created: 'User successfully created',
    user_deleted: 'User successfully deleted',
    user_restored: 'User successfully restored',
    user_updated: 'User successfully updated',
    users_import_started: 'Users import started',
  },
  btn: {
    activate_user: 'Activate user',
    add: 'Add',
    back_home: 'Back home',
    cancel: 'Cancel',
    close_ticket: 'Close ticket',
    create_ticket: 'Create new ticket',
    create_user: 'Create user',
    details: 'Details',
    delete: 'Delete',
    delete_user: 'Delete user',
    download: 'Download',
    edit_user: 'Edit user',
    hide_filters: 'Hide filters',
    logout: 'Logout',
    mark_as_read: 'Mark as read',
    no: 'No',
    ok: 'OK',
    reboot: 'Reboot',
    save_base_info: 'Save base info',
    save_bases_pico_info: 'Save Bases/Pico info',
    save_general_info: 'Save general info',
    save_import_users: 'Save Import of users',
    save_notifications_settings: 'Save notifications settings',
    see_all_notifications: 'See all notifications',
    see_more: 'See more',
    send: 'Send',
    set_password: 'Set password',
    set_user_as_admin: 'Set a user as an admin',
    show_filters: 'Show filters',
    show_image: 'Show image',
    show_ticket: 'Show ticket',
    forgot_password: 'Forgot password',
    reset_password: 'Reset the password',
    unlock: 'Unlock',
    unlock_all_picos: 'Unlock all picos',
    update: 'Update',
    update_user: 'Update user',
    upload: 'Upload',
    upload_image: 'Upload image',
    yes: 'Yes',
  },
  error: {
    email_or_nfc_id_required: 'The NFC badge ID or email field is required',
    invalid: 'Field is invalid',
    invalid_email: 'Email is invalid',
    password_min_length_8: 'Min length for password is 8 symbols',
    required: 'Field is required',
    user_with_email_not_found: 'User with this email not found',
    user_not_found: 'User not found'
  },
  label: {
    accelero_max: 'AcceleroMax',
    active_bases_out_of_total: 'Active bases out of {count}',
    active_users: 'Active users',
    admin: 'Admin',
    admin_notifications: 'Admin notifications',
    admins: 'Admins',
    advanced_details: 'Advanced details',
    alias: 'Alias',
    all: 'All',
    autoimport: 'Autoimport',
    available: 'Available',
    available_pico: 'Available pico',
    average_peak_hour: 'Average peak hour',
    base: 'Base',
    base_alias: 'Base alias',
    base_details: 'Base details',
    base_from: 'Base from',
    base_id: 'Base ID',
    base_to: 'Base to',
    bases_pico: 'Bases/Pico',
    bases_with_active_status: 'Bases with active status',
    categories: 'Categories',
    category: 'Category',
    chosen_notifications: 'Chosen notifications',
    code: 'Code',
    coming_soon: 'Coming soon',
    confirm_password: 'Confirm password',
    contact: 'Contact',
    contact_for_request: 'For any request, please contact',
    coordinates: 'Coordinates',
    create_password: 'Create new password',
    create_user: 'Create user',
    current_pico_users: 'Current pico users',
    current_users: 'Current users',
    date: 'Date',
    date_of_last_update: 'Date of last update',
    date_of_unlock: 'Date of unlock',
    days: 'Days',
    description: 'Description',
    details: 'Details',
    download_template: 'Download the standard Osol template',
    email: 'Email',
    email_notifications: 'Email notifications',
    enable_gps: 'Enable GPS collecting data',
    filters: 'Filters',
    first_name: 'First name',
    general: 'General',
    hello: 'Hello',
    history: 'History',
    holding_time: 'Holding time',
    hours: 'Hours',
    import_of_users: 'Import of users',
    information: 'Information',
    issue: 'Issue',
    issues: 'Issues',
    language: 'Language',
    last_autoimport: 'Last autoimport',
    last_name: 'Last name',
    last_update: 'Last update',
    len_log: 'Len log',
    limit_authorized_area: 'Limit the authorised area',
    limit_holding_time: 'Limit holding time',
    link_logo: 'Link logo',
    link_with_organization: 'Link with organisation',
    linked_organization: 'Linked organisation',
    list_of_organizations: 'List of organisations',
    location: 'Location',
    location_of_installation: 'Location of installation',
    lock_mode: 'Lock mode',
    log_in: 'Log in',
    long_alias: 'Long alias',
    maintenance_mode: 'Maintenance mode',
    managers_full_name: 'Manager`s name and last name',
    map: 'Map',
    maximum_radius: 'Maximum radius authorised',
    minutes: 'Minutes',
    mobile_app_notifications: 'Mobile app notifications',
    my_personal_template: 'My personal template setting for autoimport',
    my_problem_is_about: 'My problem is about',
    name: 'Name',
    new_password: 'New password',
    new_password_again: 'New password again',
    nfc_badge_id: 'NFC badge ID',
    nfc_badge_reader: 'NFC badge reader',
    nfc_id: 'NFC ID',
    no_data: 'No data',
    no_new_notifications: 'No new notifications',
    not_returned_pico: 'Not returned pico',
    not_selected: 'Not selected',
    notifications: 'Notifications',
    number_of_pico_general: 'Number of Pico General',
    number_of_pico_unlocked: 'Number of Pico Unlocked',
    number_of_pico_used_by_one_user: 'Number of Pico that can be used by one user',
    number_of_users_added: 'Number of user added',
    number_of_users_deleted: 'Number of user deleted',
    old_password: 'Old password',
    organization: 'Organisation',
    organization_name: 'Name of organisation',
    password: 'Password',
    password_recovery: 'Password recovery',
    personal_info: 'Personal info',
    phone_number: 'Phone number',
    pico_alias: 'Pico alias',
    pico_id: 'Pico ID',
    pico_taken: 'Pico taken',
    power_in: 'Power in',
    position: 'Position',
    positions: 'Positions',
    request_was_sent: 'Request was sent',
    response: 'Response',
    search: 'Search',
    settings: 'Settings',
    short_alias: 'Short alias',
    single_import: 'Single import',
    slot: 'Slot',
    soc: 'SOC',
    soh: 'SOH',
    status: 'Status',
    status_active: 'Status active',
    status_inactive: 'Status inactive',
    super_users: 'Super users',
    ticket: 'Ticket',
    tickets: 'Tickets',
    time: 'Time',
    total_consumption: 'Total consumption',
    total_time: 'Total time',
    type_of_notification: 'Type of notification',
    type_of_problem: 'Type of problem',
    unread_only: 'Unread only',
    unread_tickets: 'Unread tickets',
    upload_list_of_users: 'Upload the list of users',
    urgency: 'Urgency',
    urgent_notifications: 'Urgent notifications',
    usb_c_100_w: 'USB-c 100W',
    user_id: 'User ID',
    user_notifications: 'User notifications',
    users: 'Users',
    web_notifications: 'Web notifications',
    workplace_mobility: 'Workplace mobility',
    you: 'You',
  },
  modal: {
    activate_user: 'Are you sure that you want to activate user?',
    close_ticket: 'Are you sure that you want to close ticket?',
    delete_user: 'Are you sure that you want to delete user?',
    release_slot: 'Pico will be unlocked from the base station and get deleted status',
  },
  navigation: {
    404: 'Page not found',
    basedetails: 'Bases/Pico',
    bases: 'Bases/Pico',
    change_password: 'Change password',
    contact: 'Contact Osol',
    contact_list: 'My tickets',
    contactlist: 'My tickets',
    create_ticket: 'Create ticket',
    dashboard: 'Dashboard',
    forgot_password: 'Forgot password',
    history: 'History',
    home: 'Home',
    login: 'Login',
    newcontact: 'My tickets',
    notifications: 'Notifications',
    reset_password: 'Reset password',
    settings: 'Settings',
    showcontact: 'My tickets',
    showticket: 'Tickets',
    tickets: 'Tickets',
    users: 'Users'
  },
  placeholder: {
    enter_code: 'Enter code',
    enter_your_email: 'Enter your Email',
    enter_your_password: 'Enter your password',
    from: 'From',
    time_of_use_from: 'Time of use from',
    time_of_use_to: 'Time of use to',
    to: 'To',
    your_message: 'Your message'
  },
  status: {
    active: 'Active',
    deactivated: 'Deactivated',
    deleted: 'Deleted',
    inactive: 'Inactive',
    locked: 'Locked',
    low_activity: 'Low activity',
    off: 'Off | Maintenance',
    offline: 'Offline',
    unlocked: 'Unlocked',
  },
  text: {
    check_email_for_recovery: 'Please check your email for password recovery link',
    choose_params_for_notification: 'Choose parameters for notifications',
    click_to_delete_image: 'Click to delete image',
    contact_info: 'The main contact for all technical support is',
    page_not_found: 'Sorry, we couldn`t find that page',
  }
}